import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Framework from '../components/framework';
import Paging from '../components/blog/paging';
import Post from '../components/blog/post';
import SEO from '../components/framework/seo';

export const query = graphql`
	query BlogPosts($blog__pageLimit: Int!) {
		allMarkdownRemark(
			filter: { frontmatter: { state: { eq: "published" } } }
			limit: $blog__pageLimit
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					...MarkdownFragment
				}
			}
			pageInfo {
				pageCount
			}
		}

		background: file(relativePath: { eq: "framework/background.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1140) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

const IndexPage = ({ data, location }) => {
	const header = {
		image: data.background.childImageSharp.fluid.src,
	};
	const pageCount = data.allMarkdownRemark.pageInfo.pageCount;
	const posts = data.allMarkdownRemark.edges;

	return (
		<Framework header={header} location={location}>
			<SEO
				description="My name is Deniz, I'm a teacher with university education in art. I'm from Turkey but I live in Sweden now."
				image={data.background.childImageSharp.fluid.src}
				keywords="art, teacher, painter, photographer, sweden, turkey"
				location={location}
				title="Art Teacher-Painter, Photographer living in Sweden"
			/>
			{posts.map(({ node }) => {
				return (
					<Post
						className="mb-5"
						single={false}
						key={node.fields.slug}
						{...node}
					/>
				);
			})}
			{pageCount > 1 && (
				<Paging previous={{ title: 'Previous page', url: '/page/2' }} />
			)}
		</Framework>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
			pageInfo: PropTypes.shape({
				pageCount: PropTypes.number,
			}),
		}),
		background: PropTypes.object,
	}),
	location: PropTypes.object.isRequired,
};

export default IndexPage;
